import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroTheOther from "../components/HeroTheOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SsdlcRequirementsPhasePageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  tsrpsection,
  isisection,
  risksection,
  fixedimagesection
}) => (
    <div>
      <SEO title={seo.title} description={seo.description} article_section={seo.article_section} />
      <HeroTheOther data={hero} />
      <section className="section">
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <SubTitle title={contactus.title} />
              <SubTitle title={contactus.secondtitle} />
              <Descriptions descriptions={contactus.descriptions} margin="1rem 0" />
            </div>
            <div className="column is-1"></div>
            <div className="column is-3">
              <a href={contactus.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${aboutsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "2rem" }}>
            <Title title={aboutsection.title} color={aboutsection.textcolor} />
            <Title title={aboutsection.secondtitle} color={aboutsection.textcolor} />
            <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
          </div>
          <div className="columns">
            <div className="column is-6"></div>
            <div className="column is-6">
              <div style={{ maxWidth: 300, height: 50 }}>
                <PreviewCompatibleImage imageInfo={aboutsection.image} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${tsrpsection.sectionbg}` }}>
        <div className="container">
          <div style={{ marginTop: "8rem" }}>
            <Title title={tsrpsection.title} color={tsrpsection.textcolor} />
            <SubTitle title={tsrpsection.subheader1} color={tsrpsection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={tsrpsection.descriptions1} color={tsrpsection.textcolor} margin="1rem 0" />
            <Descriptions descriptions={tsrpsection.descriptions2} color={tsrpsection.textcolor} />
          </div>
          <div className="columns" style={{ margin: "2rem 0" }}>
            <div className="column is-8"></div>
            <div className="column is-4">
              <a href={tsrpsection.buttonlink} >
                <button style={{ background: `${tsrpsection.buttoncolor}`, fontSize: 20, width: "100%", color: "#53c5d1" }}>{tsrpsection.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div style={{ marginTop: "3rem" }}>
            <Title title={isisection.title} />
            <SubTitle title={isisection.subheader1} margin="1.5rem 0" />
            <Descriptions descriptions={isisection.descriptions1} margin="1rem 0" />
            <SubTitle title={isisection.subheader2} margin="1.5rem 0" />
            <Descriptions descriptions={isisection.descriptions2} margin="1rem 0" />
            <Descriptions descriptions={isisection.descriptions3} />
          </div>
          <div className="columns" style={{ margin: "2rem 0" }}>
            <div className="column is-8"></div>
            <div className="column is-4">
              <a href={isisection.buttonlink} >
                <button style={{ fontSize: 20, width: "100%", background: "#53c5d1" }}>{isisection.buttontxt}</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="section" style={{ background: `${risksection.sectionbg}`, paddingTop: "5rem" }}>
        <div className="container">
          <div className="columns">
            <div className="column is-4">
              <div style={{ maxWidth: 300, marginTop: 100 }}>
                <PreviewCompatibleImage imageInfo={risksection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Title title={risksection.title} color={risksection.textcolor} />
              <SubTitle title={risksection.subheader1} color={risksection.textcolor} margin="1.5rem 0" />
              <Descriptions descriptions={risksection.sidedescriptions1} margin="1rem 0" />
              <Descriptions descriptions={risksection.sidedescriptions2} />
            </div>
          </div>
          <div>
            <SubTitle title={risksection.subheader2} color={risksection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={risksection.descriptions1} margin="1rem 0" />
            <SubTitle title={risksection.subheader3} color={risksection.textcolor} margin="1.5rem 0" />
            <Descriptions descriptions={risksection.descriptions2} margin="1rem 0" />
          </div>
        </div>
      </section>
      <AboveFooter data={fixedimagesection} height="300px" />
    </div>
  )

SsdlcRequirementsPhasePageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object,
  tsrpsection: PropTypes.object,
  isisection: PropTypes.object,
  risksection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SsdlcRequirementsPhasePage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SsdlcRequirementsPhasePageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        tsrpsection={frontmatter.tsrpsection}
        isisection={frontmatter.isisection}
        risksection={frontmatter.risksection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SsdlcRequirementsPhasePage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SsdlcRequirementsPhasePage

export const pageQuery = graphql`
  query SsdlcRequirementsPhasePageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-requirements-phase" } }) {
      frontmatter {
        seo {
          title
          description
          article_section
        }
        hero {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          secondtitle
          descriptions
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tsrpsection {
          sectionbg
          textcolor
          title
          subheader
          descriptions1
          descriptions2
          buttontxt
          buttoncolor
          buttonlink
        }
        isisection {
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          descriptions3
          buttontxt
          buttonlink
        }
        risksection {
          sectionbg
          textcolor
          title
          subheader1
          sidedescriptions1
          sidedescriptions2
          subheader2
          descriptions1
          subheader3
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
